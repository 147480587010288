<template>
	<div>
		<div class="d-title">{{ pageTitle || '组件标题' }}</div>
		<v-divider/>
		<div class="d-content">
			<div class="sub-title">主标题</div>
			<v-text-field outlined dense hide-details v-model="contents[0].title"/>
			<div class="sub-title">副标题</div>
			<v-text-field outlined dense hide-details v-model="contents[0].subtitle"/>
			<div class="sub-title">简介</div>
			<v-textarea outlined dense hide-details v-model="contents[0].contentinfo"/>
			<div class="sub-title">跳转链接</div>
			<v-text-field outlined dense hide-details v-model="contents[0].clickurl"/>
			<div class="sub-title">内容管理</div>
			<div style="margin-top: 4px; color: #999999; font-size: 12px;">
				点击添加内容新增内容条，点击内容条进行编辑，拖拽内容条可调整内容排序
			</div>
			<draggable :list="contents.slice(1)">
				<div
						class="mt-5"
						v-for="(item, index) in contents.slice(1)"
						:key="index"
						style="position: relative"
						@click="$emit('show_right_form', o_form)">
					<div class="content-title">
						{{ item.title || '内容标题' + (index + 1) }}
					</div>
					<div
							style="position:absolute; top: -10px; right: -10px; z-index: 999; cursor: pointer"
							@click.stop="remove(index)">
						<v-icon class="iconfont">iconfont icon-guanbi-fill</v-icon>
					</div>
				</div>
			</draggable>
			<div class="mt-5">
				<v-btn block depressed color="primary" @click="add_contents">
					<v-icon class="iconfont mr-1" size="14">iconfont icon-zengjia</v-icon>
					添加内容
				</v-btn>
			</div>
		</div>
	</div>
</template>

<script>
import draggable from "vuedraggable";

export default {
	props: {
		pageTitle: {
			type: String,
			default: ''
		},
		items: {
			type: Array,
			default: () => []
		}
	},
	data() {
		return {
			o_form: {
				audiourl: "",
				clickurl: "",
				contentinfo: "",
				iconurl: "",
				imgurl: "",
				richtext: "",
				seq: 1,
				subtitle: "",
				subtitle3: "",
				subtitle4: "",
				title: "",
				videourl: ''
			},
			form: {},
			contents: []
		}
	},
	components: {draggable},
	created() {
		this.form = JSON.parse(JSON.stringify(this.o_form))
		this.contents = [this.form]
	},
	methods: {
		add_contents() {
			this.contents.push(this.o_form)
		},
		remove(index) {
			this.confirm.showConfirm('确定删除内容吗').then(() => {
				this.contents.splice(index + 1, 1)
			}).catch(() => {
			});
		},
	},
	watch: {
		contents: {
			handler(v) {
				this.$emit('change', v)
			},
			immediate: true,
			deep: true
		},
		items: {
			handler(v) {
				this.contents = v
			},
			immediate: true
		}
	}
}
</script>

<style scoped lang="scss">
.d-title {
	font-size: 18px;
	padding: 19px 24px;
	color: #333333;
	font-weight: 700;
}

.d-content {
	padding: 0 20px;

	.sub-title {
		font-size: 14px;
		font-weight: bold;
		margin-bottom: 4px;
		margin-top: 20px;
	}
}

.content-title {
	padding: 8px 24px;
	color: var(--v-primary-base);
	border: 1px solid var(--v-primary-base);
	font-size: 16px;
	border-radius: 4px;
}
</style>
