<template>
	<div>
		<div class="d-title">
			<div class="d-flex align-center">
				<v-text-field
						v-if="edit" v-model="item.title" dense hide-details @blur="edit = false"
						placeholder="请输入内容标题"/>
				<div v-else>
					{{ item.title || '内容标题' }}
				</div>
				<v-icon style="cursor: pointer" v-if="!edit" class="iconfont ml-2" color="primary" @click="edit = true">
					iconfont icon-bianji2
				</v-icon>
			</div>
			<v-icon class="iconfont" style="cursor: pointer" @click="$emit('close')">iconfont icon-guanbi</v-icon>
		</div>
		<v-divider/>
		<div class="d-content">
			<div class="sub-title">图像管理</div>
			<div style="font-size: 12px; color: #999999" class="mt-1">
				图片建议：宽高628x470像素，大小不能超过2MB，格式仅限于jpg、jpeg、png、webp
			</div>
			<div class="d-material">
				<material :showAddIcon="false"/>
				<div class="ml-4">
					<div class="d-flex align-center">
						<div class="mr-2">标题</div>
						<v-text-field placeholder="请输入标题" dense hide-details outlined/>
					</div>
					<div class="d-flex align-center mt-5">
						<div class="mr-2">链接</div>
						<v-text-field placeholder="请输入链接" dense hide-details outlined v-model="item.clickurl"/>
					</div>
				</div>
			</div>
			<div class="sub-title">内容简介</div>
			<v-textarea outlined dense hide-details v-model="item.contentinfo"/>
			<div class="sub-title">内容备注1</div>
			<v-text-field outlined dense hide-details v-model="item.subtitle"/>
			<div class="sub-title">内容备注2</div>
			<v-text-field outlined dense hide-details v-model="item.subtitle3"/>
			<div class="sub-title">内容备注3</div>
			<v-text-field outlined dense hide-details v-model="item.subtitle4"/>
		</div>
	</div>
</template>

<script>
import material from "@/components/common-material/material.vue";

export default {
	name: "right-form",
	components: {
		material
	},
	props: {
		form: {}
	},
	data() {
		return {
			item: {},
			edit: false
		}
	},
	watch: {
		form: {
			handler(v) {
				this.item = v
			},
			immediate: true
		},
		item: {
			handler(v) {
				this.$emit('change', v)
			},
			deep: true,
			immediate: true
		}
	}
}
</script>

<style scoped lang="scss">
.d-title {
	font-size: 18px;
	padding: 19px 24px;
	color: var(--v-primary-base);
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.d-content {
	padding: 0 20px;

	.sub-title {
		font-size: 14px;
		font-weight: bold;
		margin-bottom: 4px;
		margin-top: 20px;
	}

	.d-material {
		background: #F5F5F5;
		padding: 12px;
		margin-top: 20px;
		display: flex;
	}
}
</style>
