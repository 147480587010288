<template>
	<div class="d-wrapper">
		<div class="d-header">
			<div style="font-size: 18px; cursor: pointer" @click="returnPage">返回页面装修</div>
			<div style="font-size: 16px;" class="d-flex">
				<div>标题</div>
				<v-icon color="primary" size="16" class="iconfont ml-3">iconfont icon-bianji2</v-icon>
			</div>
			<div>
				<v-btn depressed color="primary" outlined>重置</v-btn>
				<v-btn class="ml-4" depressed color="primary">保存</v-btn>
			</div>
		</div>
		<div class="d-divider"/>
		<div class="d-body">
			<div class="d-body-left">
				<div class="d-flex align-center">
					<div style="font-size: 16px; color: #333333; font-weight: 700">基础组件</div>
					<div style="font-size: 12px; color: #999999; margin-left: 8px">可拖拽使用</div>
				</div>
				<div class="d-flex flex-wrap mt-4" style="gap: 16px">
					<draggable @end="end" v-model="base_list" class="d-item-box" v-for="(item, index) in base_list" :key="index">
						<div>
							<v-icon class="iconfont">iconfont {{ item.icon }}</v-icon>
							<div class="mt-2">{{ item.name }}</div>
						</div>
					</draggable>
				</div>
				<div class="d-flex align-center mt-4">
					<div style="font-size: 16px; color: #333333; font-weight: 700">图文排列组件</div>
					<div style="font-size: 12px; color: #999999; margin-left: 8px">可拖拽使用</div>
				</div>
				<div class="d-flex flex-wrap mt-4" style="gap: 16px">
					<draggable
							@end="end" v-model="list" :class="{'d-item-box-active': item.name === active_components_id}"
							class="d-item-box" v-for="(item, index) in list" :key="index">
						<div>
							<v-icon :color="item.name === active_components_id ? 'primary': '#333333'" class="iconfont">iconfont
								{{ item.icon }}
							</v-icon>
							<div class="mt-2">{{ item.name }}</div>
						</div>
					</draggable>
				</div>
			</div>
			<div style="width: 220px">
				<draggable :list="res_list">
					<div class="virtual-box" v-for="(item, index) in res_list" :key="index">
						<div>{{ item.name }}</div>
						<div @click="remove(index)">
							<v-icon class="iconfont" size="18" color="#ff0000">iconfont icon-shanchu</v-icon>
						</div>
					</div>
				</draggable>
			</div>
			<div class="d-body-center">
				<div style="pointer-events: none;">
					<iframe
							id="iframe"
							width="200%"
							height="200%"
							:src="pcUrl"
							style="transform: scale(.5); position: absolute; left: -50%; top: -50%"
							frameborder="0"></iframe>
				</div>
			</div>
			<div class="d-body-right">
				<right-form
						v-show="show_right_form" :form="right_form" @change="change_right_form"
						@close="show_right_form = false"/>
				<main-form
						v-show="!show_right_form"
						:page-title="active_components_id"
						@change="change_contents"
						@show_right_form="right_form = $event; show_right_form = true"
						:items="contents"/>
			</div>
		</div>
	</div>
</template>

<script>
import draggable from 'vuedraggable';
import mainForm from "./main-form.vue";
import rightForm from "./right-form.vue";
import {pcUrl} from '/public/serverconfig.json'

export default {
	components: {draggable, mainForm, rightForm},
	data() {
		return {
			pcUrl: pcUrl,
			show_right_form: false,
			right_form: {},
			base_list: [
				{name: '图文广告', icon: 'icon-tuwenguanggao-Base'},
				{name: '滚动公告', icon: 'icon-gundonggonggao-Base'},
				{name: '排列公告', icon: 'icon-hengxiangpailiegonggao-PC'},
				{name: '分割占位', icon: 'icon-fengezhanwei-Base'},
			],
			list: [
				{
					name: '多图文横向轮播',
					icon: 'icon-duotuwenlunbo-PC',
					comptype: 'PCtitletextCarousel',
					menuid: 'pctitlecarousel'
				},
				{name: '顶部轮播图', icon: 'icon-zuoyouyigelunbo-PC', comptype: 'PCmainCarousels'},
				{name: '横向排列动态公告', icon: 'icon-zuoyouyigelunbo-PC', comptype: 'PCnotice'},
				{name: '积木1+2+2', icon: 'icon-zuoyouyigelunbo-PC', comptype: 'PCimageVideoGroup'},
				{name: '3图横向轮播', icon: 'icon-zuoyouyigelunbo-PC', comptype: 'PCgroupCarousel'},
				{name: '手风琴横向展示', icon: 'icon-zuoyouyigelunbo-PC', comptype: 'PCpreviewCarousel'},
				{name: '进度条轮播', icon: 'icon-zuoyouyigelunbo-PC', comptype: 'PCprogressCarousel'},
				{name: '3图横向', icon: 'icon-zuoyouyigelunbo-PC', comptype: 'PCimages'},
				{name: '圆形单图文本', icon: 'icon-zuoyouyigelunbo-PC', comptype: 'PCroundCarousel'},
				{name: '多图轮播', icon: 'icon-zuoyouyigelunbo-PC', comptype: 'PCtitletextCarousel'},
			],
			res_list: [],
			active_components_id: '',
			active_components_index: 0,
			contents: []
		}
	},
	methods: {
		remove(index) {
			this.confirm.showConfirm('确定删除组件吗').then(() => {
				this.res_list.splice(index, 1)
			}).catch(() => {
			});
		},
		change_right_form(v) {
			if (this.contents.length > 0) {
				let _o = this.res_list[this.active_components_index]
				if (_o) {
					console.log(_o.contents)
					// _o.contents = _o.contents.push(v)
				}
				this.sendMessage()
			}
		},
		returnPage() {
			this.$router.push('/pc-decoration')
		},
		end(e) {
			this.contents = [{title: '标题名称', subtitle: '副标题名称', contentinfo: '简介内容', clickurl: ''}]
			this.active_components_id = e.item.innerText
			this.active_components_index = this.res_list.length
		},
		getContent() {
			let res = this.list.filter(item => {
				return item.name === this.active_components_id
			})
			if (res && res.length > 0 && this.contents.length > 0) {
				const o = Object.assign({}, {contents: this.contents}, res[0])
				let _o = this.res_list[this.active_components_index]
				if (_o) {
					_o.contents = this.contents
				} else {
					this.res_list.push(o)
				}
				this.sendMessage()
			}
		},
		change_contents(v) {
			this.contents = v
			this.getContent()
		},
		sendMessage() {
			let id = document.getElementById('iframe');
			if (id) {
				// 触发子页面的监听事件
				id.contentWindow.postMessage(this.res_list, '*');
			}
		}
	},
}
</script>

<style scoped lang="scss">
.d-wrapper {
	height: 100vh;
	background: #f5f5f5;
}

.d-header {
	background: #ffffff;
	height: 64px;
	display: flex;
	align-items: center;
	padding: 0 40px;
	color: var(--v-primary-base);
	justify-content: space-between;
}

.d-divider {
	height: 1px;
	background: #D8D8D8;
}

.virtual-box {
	width: 100%;
	height: 40px;
	padding: 0 10px;
	border-radius: 8px;
	margin-top: 20px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	border: 1px solid #d8d8d8;
}

.d-body {
	display: flex;
	justify-content: space-between;

	.d-body-left {
		flex-shrink: 0;
		width: 268px;
		padding: 16px;
		background: #ffffff;
		overflow-y: auto;
		height: calc(100vh - 65px);

		.d-item-box {
			border: 1px solid #D8D8D8;
			border-radius: 4px;
			width: 104px;
			height: 104px;
			display: flex;
			justify-content: center;
			align-items: center;
			text-align: center;
			font-size: 12px;
		}

		.d-item-box-active {
			color: var(--v-primary-base);
		}
	}

	.d-body-center {
		width: 768px;
		background: #ffffff;
		overflow-y: auto;
		height: calc(100vh - 65px);
		position: relative;
	}

	.d-body-right {
		width: 480px;
		flex-shrink: 0;
		background: #ffffff;
		overflow-y: auto;
		height: calc(100vh - 65px);
	}
}
</style>
